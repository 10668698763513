const testimonialdata = [
  {
    testimonialcontent:
      " Thank you sir,I am very happy that I am learning power BI from you. Your teaching skill is outstanding, amazing 😍 excellent 👌.",
    testimonialname: "Bidorha Keshari Panada",
    testimonialprofession: "Deputy Manager Procurement",
  },
  {
    testimonialcontent:
      " Congratulations on the success of your past students! I can't wait to join your upcoming group course. Thank you for sharing!",
    testimonialname: "Suhas Shelke",
    testimonialprofession: "Frontend developer",
  },
  {
    testimonialcontent: " A heartfelt thank you, our amazing mentor! ❤️🙏🏼 ",
    testimonialname: "Dharmapada Bar",
    testimonialprofession: "Student ",
  },
  {
    testimonialcontent:
      "Working with Akshay to optimize my LinkedIn profile was an absolute game-changer.I was able to improve my brand and increase my visibility on the platform",
    testimonialname: "Rohit",
    testimonialprofession: "Data Analyst",
  },
  {
    testimonialcontent:
      "Akshay was very patient with my questions and answered them in a very detailed way. She broke down everything into clear actionable steps about getting started with freelancing.",
    testimonialname: "Hemant",
    testimonialprofession: "Student",
  },
  {
    testimonialcontent:
      " Akshay gave me very good advice, which helped me to choose my career wisely I recommend 1:1 Mentorship session if you have any doubt while choosing career in Data Analyst",
    testimonialname: "Akansha",
    testimonialprofession: "Data Analyst",
  },
  {
    testimonialcontent:
      " I had the pleasure of attending Akshay Kamble's Power BI .Thanks to Akshay, I highly recommend his mock interview session to anyone looking to excel in their Power BI career.",
    testimonialname: "Amit",
    testimonialprofession: "Data Analyst",
  },
  {
    testimonialcontent:
      " Akshay is well aware of the field and the market as well.Hopefully this call is a first step towards the right for me, professionally",
    testimonialname: "Sai Kiran",
    testimonialprofession: "Developer",
  },
  {
    testimonialcontent:
      " Thank you Akshay for your guidance I was lost from weeks for choosing my career path but after talking with you things are clear now. It was really helpful Thanks a lot",
    testimonialname: "Sandra Powers",
    testimonialprofession: "Power BI developer",
  },
  {
    testimonialcontent:
      " A very worthy session that is truly worth its weight in Gold. Akshay is a top class coach / mentor. I recommend him 100% - He is the best.",
    testimonialname: "Shravani",
    testimonialprofession: "Student",
  },
];

export { testimonialdata };
