import Container from "../Container";
import { projectData } from "../../resources/projects";
import ProjectsCard from "./ProjectsCard";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { AiOutlineArrowRight } from "react-icons/ai";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { Slide } from "react-awesome-reveal";
import { Link } from "react-router-dom";

export default function ProjectSection() {
  return (
    <div className="w-full pb-[5em] mt-20">
      <Container>
        <div className="mt-12 p-4 ">
          <Slide>
            <div className="w-full text-xl md:text-3xl lg:text-4xl font-normal mx-auto">
              <p className="text-[#ffffff] lg:text-2xl sm:text-xl text-lg">
                This page consists of my portfolio comprising of 21+ projects
                belonging to various functional areas (built using publicly
                available data). All these projects as well as content related
                to analytics was shared on{" "}
                <Link
                  to={"https://www.linkedin.com/in/akshay02/"}
                  target="_blank"
                >
                  <span className="text-[#8C8C8E]">LinkedIn</span>
                </Link>{" "}
                where my content has generated:
              </p>
            </div>
            <div className="lg:text-2xl sm:text-xl text-lg font-semibold my-[3rem] py-5 ml-10 md:ml-20">
              <li className="pb-5">15+ Million Impressions</li>
              <li className="pb-5">120k+ Engagements</li>
              <li className="pb-5">20k+ Followers</li>
            </div>
            <div className="text-center my-10 pb-10">
              <h1 className="text-3xl sm:text-5xl font-semibold">Portfolio</h1>
            </div>
          </Slide>
          <Slide>
            <Carousel
              renderArrowPrev={(clickHandler, hasPrev) => {
                return (
                  <div
                    className={`${
                      hasPrev ? "absolute" : "hidden"
                    } top-0 bottom-0 left-0 flex justify-center items-center p-3 opacity-100 hover:opacity-30 cursor-pointer z-20`}
                    onClick={clickHandler}
                  >
                    <AiOutlineArrowLeft className="w-9 h-9 text-white" />
                  </div>
                );
              }}
              renderArrowNext={(clickHandler, hasNext) => {
                return (
                  <div
                    className={`${
                      hasNext ? "absolute" : "hidden"
                    } top-0 bottom-0 right-0 flex justify-center items-center p-3 opacity-100 hover:opacity-30 cursor-pointer z-20`}
                    onClick={clickHandler}
                  >
                    <AiOutlineArrowRight className="w-9 h-9 text-white" />
                  </div>
                );
              }}
              swipeable={true}
              className="px-1 sm:px-10"
            >
              {projectData.map((currEle, index) => {
                return <ProjectsCard data={currEle} />;
              })}
            </Carousel>
          </Slide>
        </div>
      </Container>
    </div>
  );
}
