const Clients = [
    {
        clientImagePath : "cognizant.png"
    },
    {
        clientImagePath : "i2econsulting.png"
    },
    {
        clientImagePath : "gyanholic.png"
    },
    {
        clientImagePath : "fortisalberta.png"
    },
    {
        clientImagePath : "Utegration.png"
    },
    {
        clientImagePath : "telemedrx.png"
    },
    {
        clientImagePath : "boe.png"
    },
    {
        clientImagePath : "ami.jpg"
    },
]

export {
    Clients
}