const Education = [
    {
        title : "Microsoft Certified: Power BI Data Analyst",
        certificationName : "PL-300 Certification",
        content : "Earning the Power BI Data Analyst Associate certification validates the skills and knowledge required to enable businesses to maximize the value of their data assets by using Microsoft Power BI.",
        duration : "DEC 2022 - DEC 2023",
        cta : "CERTIFICATE",
        url: "https://www.credly.com/badges/f1316971-ca29-40c4-9a18-aa835151bc62/linked_in?t=rnjv7i"
    },
    {
        title : "Microsoft Certified: Power Platform Fundamentals",
        certificationName : "PL-900 Certification",
        content : "Earning the Power Platform Fundamentals Certification helps me to understand Power BI, Power Apps, Power Automate, Power Virtual Agent, & Dataverse.",
        duration : "April 16, 2023",
        cta : "CERTIFICATE",
        url: "https://www.credly.com/badges/827ed1f7-91c2-41b0-8af1-dd5e18947937"
    },
    {
        title : "Microsoft Certified: Azure Fundamentals",
        certificationName : "AZ-900 Certification",
        content : "Earning the Azure Fundamentals Certification  have demonstrated foundational level knowledge of cloud services and how those services are provided with Microsoft Azure.",
        duration : "March 27, 2023",
        cta : "CERTIFICATE",
        url : "https://www.credly.com/badges/f81740a9-f86f-4758-9f1e-ef0f2ad76400"
    },
    {
        title : "Jain University",
        certificationName : "Online",
        content : "MBA in Data Science and Data Analytics from Jain University, Banglore.",
        duration : "Jan 2021 - May 2023",
        cta : ""
    },
    {
        title : "Mumbai University",
        certificationName : "Mumbai",
        content : "B.E in Computer Engineering from SES GOI FE College of Engineering, Karjat",
        duration : "Aug 2013 - May 2017",
        cta : ""
    }
]

export {
    Education
}