const bannerData = {
  name: "Akshay Kamble",
  bannerTextOne: "If you Want To Be A",
  bannerTextTwo: "Master,",
  bannerTextThree: "Learn from ",
  bannerTextFour: "master.",
  typeText: "Your Power Bi ",
  profile_image_path: "akshayAvatar.png",
};

export { bannerData };
