const aboutMe = {
  about: [
    "I'm Microsoft Certified Data Analyst with over 5 years of experience.",
    "I'm A Power BI Trainer and Freelancer.",
  ],
};

const aboutWork = {
  whatIdo: [
    "I help organizations in their Digital Transformation journey:",
    "Deployment of Power BI dashboards with Modern UI/UX",
    "Data Cleaning and transformation",
    "Reporting & Dashboarding with Augmented Reality",
    "Power BI one-on-one Training",
    "Power BI Corporate Trainings",
  ],
};

const guest = {
  title: "Are you looking for a guest speaker ?",
  content:
    "If you are looking for a guest speaker, you have come to the right place. I have successfully delivered many guest talks. I have done Sessions on Power BI, did Workshop, Webinars, One-on-One Training and Corporate Trainings as well.",
};

const story = {
  myStory: [
    "I know I did. Back in September 2017, I was just like you, a beginner with limited knowledge about Power BI. But, I took a leap of faith and decided to invest my time and resources into learning this amazing tool.",
    "My journey with Power BI was not easy. I faced many challenges, failed many times, and even lost hope at times. However, I didn’t give up. I enrolled in numerous classes, spent a lot of money, and finally, after gaining a great deal of experience and exposure, I reached a point where I was able to work on freelance projects. And today, I’m proud to say that I have assisted hundreds of students in learning Power BI through BI Academy (https://biacademy.in).",
    "My name is Akshay, and I work as a Power BI Developer, Trainer, Consultant, and Freelancer. I have honed my skills in various aspects of Power BI, including understanding business requirements in the BI context, designing data models, creating dashboards, and interactive visual reports, identifying key performance indicators (KPIs), and performing DAX queries and functions.",
    "I have also worked with Microsoft Project Online, led a team in challenging situations, and collaborated with users and team members at all levels for performance improvement and suggestions.",
    "In short, I have the knowledge and expertise to manage the entire BI system and use Power BI to make smart, real-time decisions. If you’re interested in learning more about me and my work, please visit “biacademyin”",
    "So, if you’re feeling lost in the world of Power BI, I encourage you to take a chance and invest in your learning journey. I’m confident that with the right resources and guidance, you too can reach a point where you can utilize Power BI to its full potential.",
  ],
};
export { aboutMe, aboutWork, guest, story };
