/* eslint-disable no-unused-vars */
import Container from "../Container";
import { useEffect, useMemo, useState, useRef } from "react";
import ProjectSection from "./ProjectSection";
import ExperienceSection from "./ExperienceSection";
import AboutMeSection from "./AboutMeSection";
import Myservices from "./MyServices/MyServices";
import Contacts from "./Contacts";
import "react-dropdown/style.css";

export default function MainContent() {
  const mainContentRef = useRef(null);
  const sections = useMemo(
    () => [
      {
        sectionName: "My Services",
        componentName: Myservices,
      },
      {
        sectionName: "About Me",
        componentName: AboutMeSection,
      },
      {
        sectionName: "Experiences",
        componentName: ExperienceSection,
      },
      {
        sectionName: "Projects",
        componentName: ProjectSection,
      },
      {
        sectionName: "Contacts",
        componentName: Contacts,
      },
    ],
    []
  );

  const [isActive, setIsActive] = useState(false);

  const [selectedCategory, setSelectedCategory] = useState(sections[0]);

  const [dataFromChild, setDataFromChild] = useState(null);

  const handleDataFromChild = (data) => {
    setDataFromChild(data);
    if (data === "Contacts") {
      setSelectedCategory({
        sectionName: "Contacts",
        componentName: Contacts,
      });
    }
  };

  
  const [selected, setIsSelected] = useState(sections[0].sectionName);


  useEffect(() => {
    setSelectedCategory(sections[0]);
  }, [sections]);

  const handleSectionClick = (section) => {
    setSelectedCategory(section);
  };
  const handleSectionClickMobile = (section, e) => {
    setIsSelected(e.target.textContent);
    setIsActive(!isActive);
    setSelectedCategory(section);
  };
  return (
    <div
      className="mt-[5rem] py-5"
      id="mainContentSection"
      ref={mainContentRef}
    >
      <Container>
        <div className="border-2 md:border-0 border-[#CE9FFC] px-3 py-3 rounded-full flex md:flex-row gap-x-2 justify-evenly justify-items-center my-auto">
          {sections.map((element, index) => {
            return (
              <p
                key={index}
                className={`text-[10px] sm:text-l md:text-2xl font-normal mt-auto mb-auto ${
                  selectedCategory === element
                    ? "border-2 border-[#CE9FFC] px-3 py-2 rounded-full"
                    : "border-transparent"
                } cursor-pointer`}
                onClick={() => handleSectionClick(element)}
              >
                {element.sectionName}
              </p>
            );
          })}
        </div>
      </Container>
      {/* <div className="max-h-full dropdown md:hidden"> */}
      <div className="hidden">
        <div
          onClick={(e) => {
            setIsActive(!isActive);
          }}
          className="dropdown-btn rounded-full"
        >
          <p className="mx-auto text-center">{selected}</p>
          {isActive ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M4.5 15.75l7.5-7.5 7.5 7.5"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M19.5 8.25l-7.5 7.5-7.5-7.5"
              />
            </svg>
          )}
        </div>
        <div
          className="dropdown-content"
          style={{ display: isActive ? "block" : "none" }}
        >
          {sections.map((element) => {
            return (
              <div
                className="item"
                onClick={(e) => handleSectionClickMobile(element, e)}
              >
                {element.sectionName}
              </div>
            );
          })}
        </div>
      </div>
      {selectedCategory.sectionName === "About Me" ? (
        <AboutMeSection onDataFromChild={handleDataFromChild} />
      ) : (
        sections.map((section) => {
          if (section.sectionName === selectedCategory.sectionName) {
            const Component = section.componentName;
            return <Component onDataFromChild={handleDataFromChild} />;
          } else {
            return null;
          }
        })
      )}
    </div>
  );
}
