import Marquee from "react-fast-marquee";
import { Clients } from "../../../resources/Clients";

export default function ClientsMarquee() {
  return (
    <div>
      <p className="text-center text-xl md:text-4xl font-semibold pt-10 pb-5">
        OUR CLIENTS
      </p>

      <div className=" px-5 py-10 mt-[5rem]">
        <Marquee
          className="flex flex-row justify-items-center justify-center align-middle"
          autoFill={true}
        >
          {Clients.map((element) => {
            return (
              <div className="w-10/12">
                <img
                  className="w-[172px] h-full"
                  src={require(`../../../assets/images/${element["clientImagePath"]}`)}
                  alt=""
                />
              </div>
            );
          })}
          {Clients.map((element) => {
            return (
              <div className="w-10/12">
                <img
                  className="w-[172px] h-full"
                  src={require(`../../../assets/images/${element["clientImagePath"]}`)}
                  alt=""
                />
              </div>
            );
          })}
        </Marquee>
      </div>
    </div>
  );
}
