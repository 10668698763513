import React, { useState, useRef } from "react";
import Container from "../Container";
import namebox from "../../assets/images/Namebox.svg";
import emailbox from "../../assets/images/Emailbox.svg";
import messagebox from "../../assets/images/Messagebox.svg";
import { toast, Toaster } from "react-hot-toast";
import emailjs from "@emailjs/browser";
const Contacts = () => {
  const form = useRef();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();

    if (!name || !email || !message) {
      toast.error("Please fill the required fields !");
      return;
    }
    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then(() => {
        toast.success("Your message has been sent successfully");
        setName(" ");
        setEmail(" ");
        setMessage(" ");
      })
      .catch(() => {
        toast.error("Oops ! Something went wrong");
      });
  };

  return (
    <Container className="pb-10 mt-[3rem] md:mt-0">
      <div>
        <Toaster
          toastOptions={{
            duration: 1000,
          }}
        />
      </div>
      <p className="mt-[10%] lg:text-5xl md:text-4xl sm:text-3xl text-2xl text-white font-semibold">
        Get in Touch
      </p>
      <form ref={form} onSubmit={sendEmail}>
        <div className="flex flex-row mt-10 lg:h-[5rem] md:h-[5rem] sm:h-[5rem] h-[3rem]">
          <img src={namebox} alt={namebox} className="lg:w-auto" />
          <input
            type="text"
            name="Name"
            placeholder="Name"
            className={`w-full bg-white text-black p-4 text-xl ${
              name ? "" : "placeholder-gray-400"
            }`}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="flex flex-row mt-10 md:mt-10 lg:mt-10 lg:h-[5rem] md:h-[5rem] sm:h-[5rem] h-[3rem]">
          <img src={emailbox} alt={emailbox} />
          <input
            type="email"
            name="Email"
            placeholder="Email"
            className="w-full bg-white text-black p-4 text-xl"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="flex flex-row mt-10 ">
          <img
            src={messagebox}
            alt={messagebox}
            className="lg:w-[5rem] xl:w-[5rem] md:w-[5rem] sm:w-[5rem] w-[3rem]"
          />
          <textarea
            rows="5"
            cols="40"
            name="Message"
            className="w-full bg-white text-black p-4 text-xl "
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
        <div className="flex justify-center mt-20">
          <button
            type="submit"
            className="bg-btn-gradient text-white w-[40%] h-[3rem] sm:w-[30%] sm:h-[3rem] md:w-[25%] md:h-[3rem] lg:w-[15%] lg:h-[2rem] rounded-xl"
          >
            SEND MESSAGE
          </button>
        </div>
      </form>
    </Container>
  );
};

export default Contacts;
