const projectData = [{
    title : "Travel Dashboard",
    content : "As a developer, I was responsible for developing a Power BI dashboard for a travel agency using Excel data. My role involved ensuring that the data was accurate and validated, and testing the dashboard to ensure that it met the client's requirements. I was also responsible for managing client meetings, gathering feedback, and implementing changes to the dashboard to ensure that it met their needs. This involved listening carefully to their requirements and using my expertise to create a dashboard that met their specific needs.",
    imagePath : "traveldashboard.svg",
    url : "https://app.powerbi.com/view?r=eyJrIjoiMjk4YzE3MWEtMTZmYy00MTE4LWE4YjUtNDE4YmRjZWJlMTM3IiwidCI6ImRiNWFjYmRmLTdmNmUtNDY5MC1iMjM2LWQ1NmJiYWUxMjJmYiJ9"
},
{
    title : "HR Dashboard",
    content : "I designed and developed an intuitive Power BI dashboard that enables HR professionals to gain valuable insights into employee details. The dashboard was created by leveraging Excel data, which I extracted and transformed to ensure data accuracy and consistency.",
    imagePath : "hrdashboard.svg",
    url : "https://app.powerbi.com/view?r=eyJrIjoiMGIyODYwMzAtZjIzMi00NTIwLWJlNDgtYTlmMjY5OGU4OWFiIiwidCI6ImRiNWFjYmRmLTdmNmUtNDY5MC1iMjM2LWQ1NmJiYWUxMjJmYiJ9"
},
{
    title : "Employee Dashboard",
    content : "I designed and developed an intuitive Power BI dashboard that enables HR professionals to gain valuable insights into employee details. The dashboard was created by leveraging Excel data, which I extracted and transformed to ensure data accuracy and consistency.",
    imagePath : "hrdashboard2.svg",
    url : "https://app.powerbi.com/view?r=eyJrIjoiNzU5MWRmNGEtN2E4YS00MTFmLWIzYjctOGJiZDE3Y2MwOGIxIiwidCI6ImRiNWFjYmRmLTdmNmUtNDY5MC1iMjM2LWQ1NmJiYWUxMjJmYiJ9"
},
{
    title : "Sales Dashboard",
    content : "Developed a Power BI dashboard to analyze B2B sales data, utilizing various analytical techniques such as time series, clustering, and classification. Conducted testing and validation of the data to ensure accuracy and reliability. Facilitated client meetings to gather feedback and implemented changes to the dashboard accordingly.",
    imagePath : "salesdashboard.svg",
    url : "https://app.powerbi.com/view?r=eyJrIjoiY2E0Y2EwZTgtMDY5OC00MGZlLWE0ZjMtNmE1MThkOWZkMjNjIiwidCI6ImRiNWFjYmRmLTdmNmUtNDY5MC1iMjM2LWQ1NmJiYWUxMjJmYiJ9"
},
{
    title : "More Care More Kids (Hospital Dashboard)",
    content : "I designed and developed an intuitive Power BI dashboard that enables HR professionals to gain valuable insights into employee details. The dashboard was created by leveraging Excel data, which I extracted and transformed to ensure data accuracy and consistency.",
    imagePath : "kidsdashboard.svg",
    url : "https://app.powerbi.com/view?r=eyJrIjoiNzcyMWI4YWYtNmM3Mi00ZDZiLThjMzgtZDJjMzlkOTg0YTY0IiwidCI6ImRiNWFjYmRmLTdmNmUtNDY5MC1iMjM2LWQ1NmJiYWUxMjJmYiJ9"
},{
    title: "Hotel Management Dashboard",
    content : "I have designed and developed an intuitive Power BI dashboard for hotel management that enables owners to track the progress and sales.",
    imagePath : "hotelManagementImage.svg",
    url: "https://app.powerbi.com/view?r=eyJrIjoiZDYzOTNmN2ItOWI2Ny00YWJlLTg3YjYtYTZlYjZjYTQ2YWExIiwidCI6ImRiNWFjYmRmLTdmNmUtNDY5MC1iMjM2LWQ1NmJiYWUxMjJmYiJ9"
},
{
    title: "AMI Polymer Dashboard",
    content : "I have designed and developed an intuitive Power BI dashboard for AMI Polymer that enables them to track the finances,employees and sales.",
    imagePath : "AmiPolymerDashboard.svg",
    url: "https://app.powerbi.com/view?r=eyJrIjoiNzk3ZmJjNzEtOTBjZS00NDMwLTg1NzItN2U5Y2FkMWEyNDNkIiwidCI6ImRiNWFjYmRmLTdmNmUtNDY5MC1iMjM2LWQ1NmJiYWUxMjJmYiJ9"
},
{
    title: "Godrej Dashboard",
    content : "I have designed and developed an intuitive Power BI dashboard for godrej for keeping tracks of the consumer products.",
    imagePath : "godrejDashboard.svg",
    url: "https://app.powerbi.com/view?r=eyJrIjoiZTIyNThmMjMtM2ZmMi00NzJmLTk2NDktNmIwNjU5MDZlOGQ5IiwidCI6ImRiNWFjYmRmLTdmNmUtNDY5MC1iMjM2LWQ1NmJiYWUxMjJmYiJ9"
},
{
    title: "TelemedRx Dashboard",
    content : "I have designed and developed an intuitive Power BI dashboard for TelemedRx.",
    imagePath : "telemedRxDashboard.svg",
    url: "https://app.powerbi.com/view?r=eyJrIjoiNGQyYTI3MWItYWQ4Ny00MTNjLTgzYzQtMTE1ZTNmZGYxNTBmIiwidCI6ImRiNWFjYmRmLTdmNmUtNDY5MC1iMjM2LWQ1NmJiYWUxMjJmYiJ99"
},
{
    title: "Intern Dashboard",
    content : "I have designed and developed an intuitive Power BI dashboard for keeping track of their interns.",
    imagePath : "internDashboard.svg",
    url: "https://app.powerbi.com/view?r=eyJrIjoiOWY4MDdiN2QtOWU5Yy00YTEzLTlkOTYtMmEzZTRmMDc2MGY5IiwidCI6ImRiNWFjYmRmLTdmNmUtNDY5MC1iMjM2LWQ1NmJiYWUxMjJmYiJ9"
}]

export {
    projectData
}