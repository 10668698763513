/* eslint-disable jsx-a11y/img-redundant-alt */

import { Link } from "react-router-dom";

export default function ProjectsCard({ data }) {
  return (
    <div className="bg-btn-gradient text-[#121517] min-h-full w-full rounded-2xl p-4 md:p-6 flex flex-col items-start justify-between">
      <button className="text-l sm:text-2xl px-2 py-1 text-[#ffffff] bg-[#121517] rounded my-5">
        Projects
      </button>
      <p className="text-2xl sm:py-5 sm:text-4xl font-bold text-left">
        {data.title}
      </p>
      <p className="text-sm sm:text-xl font-normal py-2 text-left">
        {data.content}
      </p>
      <Link to={data.url} target="_blank">
        <button className="btn-dashboard text-sm my-[3rem] sm:text-xl font-normal rounded-full px-2 py-2 sm:my-5">
          visit dashboard
        </button>
      </Link>
      <div className="flex justify-center justify-items-center align-middle mx-auto my-2 sm:my-5">
        <img
          className="w-full md:h-[30rem]"
          src={require(`../../assets/images/${data["imagePath"]}`)}
          alt=""
        />
      </div>
    </div>
  );
}
