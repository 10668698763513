const workHistory = [
    {
        role : "Consultant",
        company : "Utegration by Cognizant",
        achievements : [
            "Conducted a two-day Power BI workshop for my Utegration colleagues, preparing them for the certification exam.",
            "Successfully completed the Fortis Client Project, delivering 24 Power BI Dashboards within a six-month timeframe. Developed 12 dashboards for Import and 12 for Composite Connection.",
            "Developed a Proof of Concept (POC) for AWC, creating and delivering multiple dashboards in Power BI and Tableau within a two-month timeframe.",
            "Handled Client meetings and lead them, also trained interns in Power BI during Project.",
            "Achieved three Microsoft certifications: PL 300, PL 900, and AZ 900."
        ],
        duration : "Mar 2022 - Present",
        cta : "UTEGRATION",
        url: "https://www.utegration.com/"
    },
    {
        role : "Power BI Developer",
        company : "I2E Consulting",
        achievements : [
            "Conducted a two-day Power BI workshop for I2E Consulting, training over 80 colleagues in data visualization and analysis.",
            "Independently developed the TelemedRx Dashboard for doctors within a two-month timeframe.",
            "Successfully completed a three-month PHS ARK project in an accelerated timeframe of just 40 days.",
            "Executed and delivered nine major internal projects within a six-month period.",
            "Utilized Microsoft Project Online dataset to design and create a comprehensive dashboard for data analysis."
        ],
        duration : "Aug 2021 - Mar 2022",
        cta : "I2E CONSULTING",
        url : "https://www.i2econsulting.com/"
    },
    {
        role : "Power BI Trainer and Freelancer",
        company : "BI Academy",
        achievements : [
            "Successfully completed multiple projects, ensuring client satisfaction and delivering high-quality results.",
            "Facilitated client meetings to gather feedback and implemented necessary changes to enhance dashboard functionality.",
            "Developed a Sales Data Power BI dashboard, providing actionable insights for informed decision-making.",
            "Undertook freelance projects, including the creation of a USA Covid Dashboard for MSC students and other impactful visualizations.",
            "Conducted corporate training sessions, equipping professionals with practical and valuable Power BI skills.",
            "Provided personalized one-on-one training to over 100 students, fostering their proficiency in Power BI.",
            "Delivered engaging and impactful workshops and corporate training sessions on Power BI."
        ],
        duration : "Jan 2018 - Aug 2021",
        cta : "BI ACADEMY",
        url : "https://biacademy.in/"
    }
]

export {
    workHistory
}