/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import Container from "../../Container";
import powerbitraining from "../../../assets/images/powerBiTraining.png";
import practical from "../../../assets/images/practical.png";
import linkedIn from "../../../assets/images/linkedin.png";
import { Fade } from "react-awesome-reveal";
import graphImage from "../../../assets/images/graph-image.png";
export default function Offerings() {
  return (
    <div className="w-100% h-full pb-10 overflow-hidden" data-aos="fade-up">
      <Fade>
        <Container>
          <div className="flex flex-col sm:flex-col sm:w-[90%] md:w-full sm:m-auto md:flex-col sm:mt-20 mt-10">
            <div className="flex flex-col sm:flex-col gap-3 sm:gap-3 md:flex-row lg:gap-5 md:gap-3 w-full  md:m-auto">
              <div className="flex flex-row sm:h-[20%] sm:w-full gap-2 radial-gradient rounded-2xl lg:w-[60%] md:w-[60%] ">
                <img
                  src={powerbitraining}
                  alt={powerbitraining}
                  className="xl:w-[40%] lg:w-[40%] md:w-[40%] w-[40%] sm:w-[40%]"
                ></img>

                <div className="flex flex-col mt-4 sm:mt-auto sm:flex-col md:flex-col sm:m-auto md:my-auto gap-3 m-auto">
                  <p className="text-white px-3 sm:px-auto text-normal text-base sm:text-base lg:text-xl xl:text-2xl md:text-base font-semibold">
                    <a
                      href="https://biacademy.in/courses/zero-to-advance-power-bi"
                      target="_blank"
                    >
                      BI ACADEMY - Unlock Power BI With Akshay Kamble
                    </a>
                  </p>
                  <p className="text-white px-3 sm:px-auto pb-4 sm:pb-0 text-[10px] sm:text-xs md:text-xs lg:text-sm xl:text-base">
                    <a
                      href="https://biacademy.in/courses/zero-to-advance-power-bi"
                      target="_blank"
                    >
                      Learn Power BI from industry experts with our online and
                      corporate training programs. Whether you're a beginner or
                      an experienced user, we offer 1:1 personal training and
                      mock interviews to help you succeed in your career.
                    </a>
                  </p>
                </div>
              </div>

              <div className="flex flex-col radial-gradient rounded-2xl lg:w-[40%] sm:gap-3 md:gap-0 md:w-[40%]">
                <p className="text-white px-3 body ml-5 mt-4 my-auto sm:mt-3 md:mt-5 text-xl sm:text-2xl lg:text-xl md:text-base font-semibold xl:text-2xl">
                  <a href="https://biacademy.in/freelance" target="_blank">
                    FREELANCE - POWER BI PROJECTS
                  </a>
                </p>
                <p className="text-white px-3 body ml-5 my-auto mt-3 text-sm pb-4 sm:mt-auto sm:text-base sm:pb-4 md:pb-0 md:text-xs xl:text-base lg:text-sm">
                  <a href="https://biacademy.in/freelance" target="_blank">
                    Need help with your Power BI projects? Look no further! Our
                    experienced freelancers offer daily, weekly, and monthly
                    plans to fit your needs. From data modeling to report
                    design, we'll help you bring your data to life and make
                    informed business decisions
                  </a>
                </p>
              </div>
            </div>
            <div className="flex sm:gap-4 gap-3 flex-col sm:flex-col md:flex-row md:gap-3 lg:gap-4 md:mt-5 sm:mt-3 lg:mt-3 w-[100%] mt-3 ">
              <div className="flex flex-col sm:flex-col md:flex-col radial-gradient lg:gap-4 md:gap-3 sm:gap-2 rounded-2xl md:w-[30%] h-auto ">
                <p className="text-white ml-5 text-xl sm:text-2xl md:w-auto sm:ml-5 m-auto mt-10 sm:m-auto sm:mt-10 font-semibold lg:text-xl xl:text-2xl md:text-base md:text-center md:mt-10 body lg:ml-5 lg:mt-10 ">
                  <a href="https://biacademy.in/careers" target="_blank">
                    JOB HUNT SERVICES-LINKEDIN OPTIMIZATION & RESUME BUILDING
                  </a>
                </p>
                <p className="text-white m-auto mt-3 ml-5  text-sm sm:m-auto sm:mt-7  sm:text-base sm:w-auto sm:ml-5 xl:text-base lg:text-sm md:w-auto md:ml-5 md:text-xs lg:w-auto font-normal body lg:ml-5 ">
                  <a href="https://biacademy.in/careers" target="_blank">
                    Land your dream job with our job hunt services. We offer
                    LinkedIn Boost, a service that optimizes your LinkedIn
                    profile and provides content creation and posting strategies
                    to increase your visibility and reach.
                  </a>
                </p>
                <img
                  src={practical}
                  alt={practical}
                  className=" lg:m-auto md:m-auto sm:w-[60%] w-[60%] xl:w-[60%] m-auto sm:m-auto md:w-full"
                />
              </div>
              <div className="flex flex-col sm:gap-3 lg:gap-4 md:gap-3 md:w-[70%] lg:w-[80%] gap-3 ">
                <div className="flex flex-row gap-3 rounded-3xl radial-gradient h-[50%] sm:h-[100%] sm:w-[100%] overflow-hidden md:h-[60%] sm:pb-3 md:pb-auto pb-5 ">
                  <img
                    src={linkedIn}
                    alt={linkedIn}
                    className=" md:my-auto lg:ml-4 p-4 xl:w-full md:w-[100%] md:h-[90%] sm:w-[100%] mt-3 sm:mt-4  overflow-hidden sm:ml-4 sm:m-auto"
                  />

                  <div className="flex flex-col m-auto px-2 mt-3 sm:m-auto gap-4">
                    <p className="text-white lg:text-xl xl:text-2xl md:text-base sm:text-lg text-base font-semibold m-auto">
                      <a href="https://biacademy.in/careers" target="_blank">
                        ONE TO ONE MENTORSHIP & CAREER GUIDANCE
                      </a>
                    </p>
                    <p className="text-white  text-[10px] md:text-xs xl:text-base lg:text-sm sm:text-xs font-normal">
                      <a href="https://biacademy.in/careers" target="_blank">
                        Take control of your career with our one-on-one
                        consulting services. Our experienced career coaches will
                        help you plan your career path, navigate job search
                        techniques, and provide valuable insights into your
                        industry.
                      </a>
                    </p>
                  </div>
                </div>
                <div className="flex flex-col md:flex-row gap-3 md:gap-3 md:h-[40%]">
                  <img className="hidden md:flex" src={graphImage} alt="" />
                  <div className="flex flex-col p-4 px-2 radial-gradient rounded-3xl gap-3 md:gap-0 m-auto h-[100%] md:w-[58%]">
                    <p className="text-white font-semibold mt-4 text-xl sm:mt-4 md:mt-0 sm:text-2xl xl:text-2xl lg:text-xl md:text-base m-auto text-center">
                      <a href="https://biacademy.in/corporate" target="_blank">
                        CORPORATE TRAINING IN POWER BI WITH AKSHAY KAMBLE
                      </a>
                    </p>
                    <p className="text-white text-base pb-4 xl:text-base sm:pb-4 md:pb-0 lg:text-sm md:text-xs m-auto ml-4 ">
                      <a href="https://biacademy.in/corporate" target="_blank">
                        Gain expertise in Power BI through our comprehensive
                        corporate training programs led by industry experts.
                        Whether you are a novice or an advanced user, we provide
                        tailored online or offline training sessions that cater
                        to your specific needs.
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Fade>
    </div>
  );
}
