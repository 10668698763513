import Container from "../../Container";
import { testimonialdata } from "../../../resources/Testimonial";
import Noise from "../../../assets/images/noise.png";
import { FaQuoteLeft } from "react-icons/fa";
import Slider from "react-slick";
import testimonialprofile from "../../../assets/images/user.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Testimonials() {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 780,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="w-full">
      <Container>
        <div className="py-14" data-aos="fade-up">
          <div className="w-full flex justify-center">
            <h1 className="max-w-[920px] text-xl sm:text-4xl font-semibold text-center">
              Testimonials
            </h1>
          </div>

          <div className="mt-12 p-4 ">
            <Slider {...settings} className="px-2 sm:px-10 ">
              {testimonialdata.map((element, index) => {
                return (
                  <>
                    <div
                      style={{
                        backgroundImage: `url(${Noise})`,
                        opacity: 1,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                      className="h-[380px] rounded-2xl p-6 flex flex-col bg-slate-500 items-start justify-between"
                    >
                      <FaQuoteLeft className="text-white" size={24} />

                      <p className="px-2 font-medium text-lg ">
                        {element.testimonialcontent}
                      </p>

                      <div className="w-full flex items-stretch justify-between">
                        <img
                          src={testimonialprofile}
                          alt="User"
                          className="w-14 h-14 object-cover rounded-full"
                        />
                        <div className="h-full flex flex-col justify-between">
                          <h3 className="text-base sm:text-lg font-semibold">
                            {element.testimonialname}
                          </h3>
                          <p className="text-sm sm:text-base text-slate-100">
                            {element.testimonialprofession}
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </Slider>
          </div>
        </div>
      </Container>
    </div>
  );
}
