import { Slide } from "react-awesome-reveal";
import Offerings from "./Offerings";
import Testimonials from "./testimonial";
import ClientsMarquee from "./ClientsMarquee";

export default function MyServices() {
  return (
    <div className="pb-[5em] mt-[2rem]">
      <Slide>
        <Offerings />
        <Testimonials />
        <ClientsMarquee />
      </Slide>
    </div>
  );
}
