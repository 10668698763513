/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import Container from "../Container";
import logo from "../../assets/images/logo.svg";
import { useNavigate } from "react-router";
import FooterLinkedIn from "../../assets/images/linkedin.svg";
export default function Footer() {
  const courselist = [
    {
      slug: "data-analytics-excel-power-bi-sql",
    },
    {
      slug: "zero-to-advance-power-bi",
    },
    {
      slug: "python-for-data-analytics-from-scratch",
    },
    {
      slug: "power-bi-project-training-5-dashboards",
    },
  ];
  const navigate = useNavigate();
  const handleNavigate = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div className="bg-btn-gradient w-full h-full  pb-16 lg:pb-10 xl:pb-20  md:pb-10  sm:pb-10">
        <Container>
          <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-7 sm:gap-7 md:gap-8 lg:gap-10">
            <div className="flex flex-col gap-3  xl:mt-[6rem] lg:mt-[7rem] md:mt-[5rem] sm:mt-[5rem] mt-10 sm:border-r border-white ">
              <a href="https://biacademy.in/courses" target="_blank">
                <img
                  src={logo}
                  alt={logo}
                  width={50}
                  height={50}
                  className="xl:mt-[-50px] lg:mt-[-70px] md:mt-[-70px] sm:mt-[-50px] sm:ml-5 md:ml-0 pt-7 "
                />
              </a>
              <p className="text-white text-base ">
                We at BI Academy, are dedicated to empowering individuals with
                the skills they need to excel in today's data-driven world.
              </p>
            </div>
            <div className="hidden sm:flex flex-col gap-3 xl:mt-[6rem] lg:mt-[7rem] md:mt-[5rem] sm:mt-[5rem] mt-2 sm:border-r border-white">
              <a href="https://biacademy.in/courses" target="_blank">
                <p className="text-white text-lg underline cursor-pointer">
                  Our Courses
                </p>
              </a>
              <div className="text-white text-base flex flex-col gap-2 mt-3">
                <a
                  href={`https://biacademy.in/courses/${courselist[0].slug}`}
                  target="_blank"
                >
                  <p className="text-white text-base body cursor-pointer">
                    Data Analytics
                  </p>
                </a>
                <a
                  href={`https://biacademy.in/courses/${courselist[1].slug}`}
                  target="_blank"
                >
                  <p className="text-white text-base body cursor-pointer">
                    Power BI
                  </p>
                </a>
                <a
                  href={`https://biacademy.in/courses/${courselist[2].slug}`}
                  target="_blank"
                >
                  <p
                    className="text-white text-base body cursor-pointer"
                    onClick={() =>
                      handleNavigate(
                        `https://biacademy.in/courses/${courselist[2].slug}`
                      )
                    }
                  >
                    Python
                  </p>
                </a>
                <a
                  href={`https://biacademy.in/courses/${courselist[3].slug}`}
                  target="_blank"
                >
                  <p className="text-white text-base body cursor-pointer">
                    Power BI Projects
                  </p>
                </a>
              </div>
            </div>
            <div className="hidden sm:flex flex-col gap-3 xl:mt-[6rem] lg:mt-[7rem] md:mt-[5rem] sm:mt-[5rem] mt-2 md:border-r border-white">
              <a href={`https://biacademy.in/careers`} target="_blank">
                <p className="text-white text-lg underline cursor-pointer">
                  Career Services
                </p>
              </a>
              <div className="text-white text-base flex flex-col gap-2 mt-3">
                <a href={`https://biacademy.in/careers`} target="_blank">
                  <p className="text-white text-base body cursor-pointer">
                    Career Assistance
                  </p>
                </a>
                <a href={`https://biacademy.in/freelance`} target="_blank">
                  <p className="text-white text-base body cursor-pointer">
                    Freelance
                  </p>
                </a>
                <a href={`https://biacademy.in/mocktests`} target="_blank">
                  <p className="text-white text-base body cursor-pointer">
                    Mock Test
                  </p>
                </a>
                <a href={`https://biacademy.in/corporate`} target="_blank">
                  <p className="text-white text-base body cursor-pointer">
                    Corporate training
                  </p>
                </a>
              </div>
            </div>
            <div className="hidden sm:flex flex-col gap-3 xl:mt-[6rem] lg:mt-[7rem] md:mt-[5rem] sm:mt-[2rem] mt-2 sm:border-r lg:border-r border-white">
              <p className="text-white text-lg underline">Explore More</p>
              <div className="text-white text-base flex flex-col gap-2 mt-3">
                <a href={`https://biacademy.in/aboutus`} target="_blank">
                  <p className="text-white text-base body cursor-pointer">
                    About Us
                  </p>
                </a>
                <a href={`https://biacademy.in/store`} target="_blank">
                  <p className="text-white text-base body cursor-pointer">
                    Store
                  </p>
                </a>
                <a href={`https://biacademy.in/hirefromus`} target="_blank">
                  <p className="text-white text-base body cursor-pointer">
                    Hire From Us
                  </p>
                </a>
                <a href={`https://biacademy.in/refer`} target="_blank">
                  <p className="text-white text-base body cursor-pointer">
                    Refer A Friend
                  </p>
                </a>
              </div>
            </div>
          </div>
          <div>
            <hr className="w-full h-[2px] mt-[6%]" />
          </div>
          <div className="grid grid-cols-8 sm:grid-cols-12 gap-10 mt-5 lg:ml-10">
            <p className="hidden sm:flex text-lg sm:col-span-2">
              Let's connect :)
            </p>
            <p className="hidden sm:flex text-lg sm:col-span-2">
              +91 9766889002
            </p>
            <a
              href="mailto:connect@biacademy.in"
              className="text-lg col-span-5 sm:col-span-4 xl:text-center"
            >
              <p>connect@biacademy.in</p>
            </a>
            <a
              href="https://www.linkedin.com/in/akshay02/"
              target="_blank"
              className="hidden sm:flex sm:col-span-2 gap-1"
            >
              <img src={FooterLinkedIn} alt="linkedin" width={30} height={30} />
              <p className="text-lg md:mt-1">LinkedIn</p>
            </a>
            {/* <a href="mailto:akshaykamble@gmail.com" className="flex gap-1">
              <img src={FooterGmail} alt="gmail" width={28} height={28} />
              <p className="text-lg">Gmail</p>
            </a> */}
            <a
              href={`https://biacademy.in/contact-us`}
              target="_blank"
              className="hidden sm:flex sm:col-span-2 gap-1 mt-2"
            >
              <p className="text-white sm:text-base body cursor-pointer">
                Contact Us
              </p>
            </a>
          </div>
        </Container>
      </div>
    </>
  );
}
